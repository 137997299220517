import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import CallIcon from "@mui/icons-material/Call";
import { routeList } from "../../../routes/user/routes";
import { Link, BrowserRouter as Router } from "react-router-dom";
import { Button } from "@mui/material";
import Logo from '../../../assets/img/logo.png'
import { containerMaxWith } from "../../../utils/helpers";
function ResponsiveAppBar() {
  const appBarLink = { color: "#000000", fontWeight:'bold', textDecoration: "none", marginRight:'30px',marginTop:'15px' };
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [isScrolled, setIsScrolled] = React.useState(false);
  //   const [anchorElUser, setAnchorElUser] = React.useState(null);
  React.useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  //   const handleOpenUserMenu = (event) => {
  //     setAnchorElUser(event.currentTarget);
  //   };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  //   const handleCloseUserMenu = () => {
  //     setAnchorElUser(null);
  //   };
  const numberClick = () => {
    window.location.href = 'tel:0474769147'
  }
  return (
    <AppBar position="fixed" sx={{ boxShadow: "none", backgroundColor: isScrolled ? "#f3f3f3" : "transparent" }}>
      <Container maxWidth={containerMaxWith}>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={Logo} width={170} alt="logo" />
          </Typography>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={Logo} width={170} alt="logo" />
          </Typography>
          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} sx={{ color: "#012066" }}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <Router>
                {routeList.map((item, index) => (
                  <MenuItem key={index} onClick={handleCloseNavMenu}>
                    <Link to={item.path} style={appBarLink}>
                      {item.name}
                    </Link>
                  </MenuItem>
                ))}
              </Router>
            </Menu>
          </Box>
          {/* left side links */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            
          </Box>
          {/* right side links */}
          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
          <Router>
              {routeList.map((item, index) => (
                <Link to={item.path} key={index} style={appBarLink}>
                  {item.name}
                </Link>
              ))}
            </Router>
          <Button onClick={numberClick} size="large" color="secondary" variant="contained" startIcon={<CallIcon sx={{ backgroundColor: "primary.main", borderRadius: "50%", marginLeft: "-10px", padding: 0.7 }} color="secondary" size="large" />} sx={{ borderRadius: 10, startIcon: { marginLeft: "-5px" }, border:'solid 3px', borderColor:'primary.main',pt:0.5,pb:0.5 }}>
            <Typography sx={{ fontWeight: "bold" }} variant="body1">
            047 476 9147
            </Typography>
          </Button>
          </Box>
          {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
