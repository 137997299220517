import Home from "../../pages/Home";

const routeList = [
    {
        'path':'/',
        'name':'Home',
        'element':<Home />,
        'exact':true
    },{
        'path':'/',
        'name':'About',
        'element':<Home />,
        'exact':true
    }
]

export {routeList}