import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {  Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function AlertDialog(props) {
  const { open, title, message } = props;
  const [op, setOp] = React.useState(open);
  const handleClose = () => {
    setOp(false);
  };

  return (
    <div>
      <Dialog
        open={op}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { borderRadius: 10 },
        }}
      >
        {/* {title !== undefined && <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
} */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box sx={{textAlign:'center'}}>
                <CheckCircleIcon sx={{fontSize:'50px', color:'success.light'}}/> 
            
            <Typography variant="h6" >
              {title}
            </Typography>
            <Typography variant="caption"  color="textSecondary">
              {message}
            </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
