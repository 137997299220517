import { LoadingButton } from "@mui/lab"


const Button = (props) => {
    const {name, fullWidth, onClick, loading} = props
    return (
        <LoadingButton fullWidth={fullWidth} variant="contained" sx={{borderRadius:10}} color="primary" onClick={onClick} loading={loading}>
            {name}
        </LoadingButton>
    )
}

export default Button