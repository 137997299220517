import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import Button from "../../common/Button/Button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import { TimePicker } from "@mui/x-date-pickers";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
const Step2Mobile = (props) => {
  const { nameChange, name, phoneNumberChange, phoneNumber, emailChange, email, selectedDate, handleDateChange, selectedTime, handleTimeChange, handleStep2Click, error, isPhoneNumberValid } = props;
  const localizationOptions = {
    formats: {
      time24h: 'HH:mm', // Set the time format to 24-hour (e.g., "14:30")
    },
  };
  return (
    <Box sx={{ mt: 3 }}>
      <TextField size="small" fullWidth label="Name" variant="outlined" onChange={nameChange} value={name} />
      <TextField
        type="number"
        size="small"
        fullWidth
        label="Phone Number"
        variant="outlined"
        InputProps={{
          startAdornment: <InputAdornment position="start">04</InputAdornment>,
        }}
        sx={{ mt: 3 }}
        onChange={phoneNumberChange}
        value={phoneNumber}
      />
      {!isPhoneNumberValid && <Typography color='error' variant="caption">Phone number is not valid</Typography> }
      <TextField type='email' size="small" fullWidth label="Email" variant="outlined" onChange={emailChange} value={email} sx={{ mt: 3 }} />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disablePast
          sx={{ mt: 3, width: "100%" }}
          value={selectedDate}
          onChange={handleDateChange}
          slotProps={{
            textField: {
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <EventOutlinedIcon />
                  </InputAdornment>
                ),
              },
              size: "small",
              label: "Bookin Date",
            },
          }}
        ></DatePicker>
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          sx={{ mt: 3, width: "100%" }}
          value={selectedTime}
          onChange={handleTimeChange}
          slotProps={{
            textField: {
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <ScheduleOutlinedIcon />
                  </InputAdornment>
                ),
                placeholder:"hh:mm:ss"
              },
              size: "small",
              label: "Bookin Time",
            },
          }}
          {...localizationOptions}
        ></TimePicker>
      </LocalizationProvider>
      <Typography variant="body2" color="error" sx={{ m: 1 }} align="center">
        {error && error.message}
      </Typography>
      <Box sx={{ mt: 3 }}>
        <Button name="Next" fullWidth={true} onClick={handleStep2Click} loading={false} />
      </Box>
    </Box>
  );
};

export default Step2Mobile;
