import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
// This is the way to import an SVG file and then pass it as a props

import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import { ReactComponent as CompanyIcon } from "../../../assets/img/logo.svg";

const WhatsAppBtn = (props) => {
  const { phoneNumber } = props;
  return (
    <React.Fragment>
      <Box position="fixed" bottom={45} right={10}>
        <Typography
          variant="body2"
          sx={{
            backgroundColor: "#36d147",
            pt: 0.5,
            pb: 0.5,
            pl: 2,
            pr: 0.5,
            color: "white",
            borderTopLeftRadius: 30,
            borderBottomLeftRadius: 30,
            fontWeight:'bold',
            "&::before": {
              content: '""',
              position: "absolute",
              top: "50%",
              left: "-15px",
              transform: "translateY(-50%) rotate(180deg)",
              borderWidth: "10px",
              borderStyle: "solid",
              borderColor: "transparent transparent transparent #36d147",
            },
          }}>
          Message us
        </Typography>
      </Box>
      <WhatsAppWidget CompanyIcon={CompanyIcon} companyName="Perth Airport Transfer" message={"Hi there! \uD83D\uDC4B\uD83C\uDFFC \n\nWould you like to book a trip to or from Perth Airport?"} phoneNumber={phoneNumber} />
    </React.Fragment>
  );
};

export default WhatsAppBtn;
