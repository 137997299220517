import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { routeList } from './routes';
const UserRoutes = () => {
    return (
        <Router>
            <Routes>
                {routeList.map((item, index) => (
                    <Route exact={item.exact} path={item.path} element={item.element} key={"route_"+index}/>
                ))
                }
            </Routes>
        </Router>
    );
};

export default UserRoutes;
