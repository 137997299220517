import axios from "axios"

const baseApiURL = 'https://api.perthairporttransfer.com/api/' 
const baseWebURL = 'https://api.perthairporttransfer.com/' 


// const baseApiURL = 'http://127.0.0.1:8000/api/' 
// const baseWebURL = 'http://localhost:3000/' 

const sendRequest = axios.create({
    baseURL: baseApiURL, 
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
})


const requestGet = async (url) => {
    return await sendRequest.get(url)
}
const requestPost = async (url, data) => {
    return await sendRequest.post(url, data)
}

export { requestGet, requestPost, baseWebURL,baseApiURL }