import './App.css';
import React from 'react';
import UserRoutes from './routes/user';
import ResponsiveAppBar from './components/user/AppBar/AppBar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from './components/user/Footer/Footer';
function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#012066', // Replace with your desired primary color
      },
      secondary: {
        main: '#ffffff', // Replace with your desired primary color
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <ResponsiveAppBar />
      <UserRoutes />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
