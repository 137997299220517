import { Box, Grid, Typography } from "@mui/material";
import Button from "../../common/Button/Button";

const Step4Mobile = (props) => {
  const { reviewData , handleConfirmOrderClick, btnLoading } = props;
  const options = { hour12: true };
  return (
    <Box sx={{ mt: 3 }}>
      <Grid item xs={12} sx={{textAlign:'center'}}>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Name: <strong>{reviewData[2]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Phone Number: <strong>04{reviewData[3]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Email: <strong>{reviewData[4]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Pick Up: <strong>{reviewData[0]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Drop Off: <strong>{reviewData[1]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              No. of Passengers: <strong>{reviewData[8]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Date: <strong>{reviewData[5].$d.toLocaleDateString()}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Time: <strong>{reviewData[6].$d.toLocaleTimeString(undefined, options)}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Flight Number: <strong>{reviewData[7]}</strong>
            </Typography>
            <Typography sx={{ mb: 1 }} variant="body2" color="textSecondary">
              Distance: <strong>{reviewData[9] && reviewData[9].text}</strong>
            </Typography>
          </Grid>
      <Box sx={{ mt: 3 }}>
        <Button name="Confirm Booking" fullWidth={true} onClick={handleConfirmOrderClick} loading={btnLoading} />
      </Box>
    </Box>
  );
};

export default Step4Mobile;
