import { Typography } from "@mui/material"
import { Box } from "@mui/system"

const Footer = () => {
    return (
        <Box sx={{mt:5, mb:5, textAlign:'center', width:'100%'}}>
            <Typography variant="caption" sx={{lineHeight:0.5}}>
                Copyright © 2023<br/><span style={{fontWeight:'bold', color:'primary.main'}}>Perth Airport Transfer</span>
            </Typography>
        </Box>
    )
}

export default Footer;