import { requestGet, requestPost } from "./RequestService"

const confirmBooking = async (data) => {
    return await requestPost('/confirm-booking', data);
}

const get = async () => {
    return await requestGet('/calculate');
}
const completed = async (data) => {
    return await requestPost('/completed', data);
}
const getFare = async (data) => {
    return await requestPost('/calculate', data);
}
export {confirmBooking, get, completed, getFare}