import { Box, Grid, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import FlightOutlinedIcon from "@mui/icons-material/FlightOutlined";
import Button from "../../common/Button/Button";
import { useJsApiLoader } from "@react-google-maps/api";
import { useEffect } from "react";
const libraries = ["places", "geometry"];
const Step1DeskTop = (props) => {
  const { pickUpChange, dropOffChange, handleStep1Click, error } = props;
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCJ-1iwhdrENQCUunZ4qcAlXF9pSnsq8bg",
    libraries: libraries,
  });
  useEffect(() => {
    if (isLoaded) {
      // Create a bounding box with sides ~50km away from the center point
      const pickUpInput = document.getElementById("pickup-input");
      const dropOffInput = document.getElementById("dropoff-input");
      var options = {
        types: ["geocode"],
        componentRestrictions: { country: "au" },
        bounds: createBounds(),
        strictBounds: true,
      };
      var autocomplete1 = new window.google.maps.places.Autocomplete(pickUpInput, options);
      var autocomplete2= new window.google.maps.places.Autocomplete(dropOffInput, options);
      autocomplete1.addListener("place_changed", function () {
        var place = autocomplete1.getPlace();
        if(place){
          pickUpChange(place.formatted_address)
        }else{
          pickUpChange("")
        }
      });
      autocomplete2.addListener("place_changed", function () {
        var place = autocomplete2.getPlace();
        if(place){
          dropOffChange(place.formatted_address)
        }else{
          dropOffChange("")
        }
      });
    }
  }, [isLoaded]);
  // Create bounds object for 50-kilometer radius from Perth Airport
  function createBounds() {
    var airportLocation = new window.google.maps.LatLng(-31.9409, 115.9663); // Perth Airport coordinates
    var bounds = new window.google.maps.LatLngBounds();

    // Set bounds as a circle with 50-kilometer radius
    bounds.extend(window.google.maps.geometry.spherical.computeOffset(airportLocation, 50000, 0));
    bounds.extend(window.google.maps.geometry.spherical.computeOffset(airportLocation, 50000, 90));
    bounds.extend(window.google.maps.geometry.spherical.computeOffset(airportLocation, 50000, 180));
    bounds.extend(window.google.maps.geometry.spherical.computeOffset(airportLocation, 50000, 270));

    return bounds;
  }
  return (
    <Paper sx={{ pt: 4, pb: 4, pl: 6, pr: 6, borderRadius: 10, mt: -15, zIndex: 0, position: "relative" }}>
      <Typography align="left" variant="body1" sx={{ mb: 3 }}>
        Please enter your pick up & drop off addresses:
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box sx={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
            <TextField
              size="small"
              fullWidth
              label="Pick Up"
              variant="outlined"
              InputProps={{
                "aria-label": "Place",
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              id="pickup-input"
            />
            <FlightOutlinedIcon color="secondary" sx={{ backgroundColor: "primary.main", p: 1, ml: 0.5, borderRadius: 1 }} />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <TextField
              size="small"
              fullWidth
              label="Drop Off"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              id="dropoff-input"
            />
            <FlightOutlinedIcon color="secondary" sx={{ backgroundColor: "primary.main", p: 1, ml: 0.5, borderRadius: 1 }} />
          </Box>
        </Grid>
      </Grid>
      <Typography variant="body2" color="error" sx={{ m: 1 }} align="center">
        {error && error.message}
      </Typography>
      <Box sx={{ mt: 3 }}>
        <Button name="Next" fullWidth={true} onClick={handleStep1Click} loading={false} />
      </Box>
    </Paper>
  );
};

export default Step1DeskTop;
